const bluebox = require('./bluebox');
const partykungen = require('./partykungen');
const teddypost = require('./teddypost');
// const presenteriet = require('./presenteriet');
const partyninja = require('./partyninja');

const products = bluebox.concat(partykungen, teddypost, partyninja);

const uniqueProducts = [...new Map(products.map((item) => [item.title, item])).values()];

const presenter = require('./presenter');

module.exports = {
  products: uniqueProducts,
  presenter,
};
